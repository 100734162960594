import store from './store'

export default class ErrorBag {
  constructor () {
    this.message = null
    this.errors = {}
  }

  get (field) {
    if (this.errors[field]) {
      return this.errors[field][0]
    }
  }

  store (response) {
    if (response.message) {
      this.message = response.message
      store.commit('setErrorMessage', this.message)
    }
    if (response.errors) {
      this.errors = response.errors
    }
  }

  delete (field) {
    delete this.errors[field]
  }

  clear () {
    store.commit('setErrorMessage', null)
    store.commit('setSuccessMessage', null)
    this.message = null
    this.errors = {}
  }
}
