<template>
  <div :class="className">
    <div class="flex flex-wrap -mx-2">
      <div class="flex-grow px-2">
        <label :for="id" v-if="label" class="block mb-2">{{ label }}</label>
      </div>
      <div class="flex-shrink px-2">
        <tooltip v-if="tooltip">{{ tooltip }}</tooltip>
      </div>
    </div>

    <input
      type="file"
      :id="id"
      @change="handleInput"
      class="w-full py-2 outline-none"
      ref="file"
      :accept="accept"
      :multiple="multiple"
    />
    <div v-if="error" class="text-red-600 text-sm mt-2 font-bold">
      <i class="fas fa-exclamation-triangle"></i>
      {{ error }}
    </div>
  </div>
</template>

<script>
import randomstring from 'randomstring'

export default {
  props: {
    className: {
      type: String,
      default: 'mb-6'
    },
    label: {
      type: String
    },
    tooltip: {
      type: String
    },
    error: {
      type: String
    },
    accept: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      id: randomstring.generate()
    }
  },
  methods: {
    handleInput () {
      this.$emit('change', this.multiple ? this.$refs.file.files : this.$refs.file.files[0])
    }
  }
}
</script>
