<template>
  <div>
    <div class="flex justify-between">
      <h1 class="font-bold text-xl mb-6">My Account</h1>
      <primary-btn @click="logout"> Log Out </primary-btn>
    </div>

    <loader v-if="loading" />
    <div v-else class="w-full flex flex-wrap -mx-2">
      <div class="w-full lg:w-1/2 px-2">
        <h2 class="text-lg font-bold mb-3">Account Settings</h2>

        <div class="flex bg-gray-100 p-3 mb-6 items-center">
          <i class="fas fa-info-circle mr-3"></i>
          <div class="flex-grow">
            Some account information can only be updated on your Nurtur Ecosystem account.
          </div>
        </div>

        <text-input
          v-model="accountForm.first_name"
          label="First Name"
          :error="errorBag.get('first_name')"
          disabled
        />
        <text-input
          v-model="accountForm.surname"
          label="Surname"
          :error="errorBag.get('surname')"
          disabled
        />
        <email-input
          v-model="accountForm.email"
          label="E-Mail Address"
          :error="errorBag.get('email')"
          disabled
        />
        <text-input
          v-model="accountForm.phone"
          label="Phone Number"
          :error="errorBag.get('phone')"
        />
        <text-input
          v-model="accountForm.mobile"
          label="Mobile Number"
          :error="errorBag.get('mobile')"
        />

        <div class="flex justify-end">
          <primary-btn
            :loading="updatingAccount"
            @click="saveAccountInformation"
          >
            Save
          </primary-btn>
        </div>
      </div>

      <div class="w-full lg:w-1/2 px-2">
        <h2 class="text-lg font-bold mb-3">Profile Photo</h2>

        <file-input
          @change="avatarFile = $event"
          label="New Profile Photo"
          :error="errorBag.get('avatar')"
          accept="image/*"
        />

        <div class="flex justify-end">
          <primary-btn :loading="updatingAvatar" @click="saveAvatar">
            Save
          </primary-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/http";
import ErrorBag from "@/ErrorBag";
import PrimaryBtn from "@/components/PrimaryBtn";
import FileInput from "@/components/FileInputComponent";
import EmailInput from "@/components/EmailInputComponent";
import TextInput from "@/components/TextInputComponent";
import Loader from "@/components/Loader";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    PrimaryBtn,
    FileInput,
    EmailInput,
    TextInput,
    Loader,
  },
  data() {
    return {
      accountForm: {
        title: "",
        first_name: "",
        surname: "",
        email: "",
        phone: "",
        mobile: ""
      },
      passwordForm: {
        password: "",
        password_confirmation: "",
        current_password: "",
      },
      avatarFile: null,
      errorBag: new ErrorBag(),
      updatingAccount: false,
      updatingPassword: false,
      updatingAvatar: false,
      loading: true,
      titles: [
        { name: "Mr", id: "Mr" },
        { name: "Miss", id: "Miss" },
        { name: "Mrs", id: "Mrs" },
        { name: "Ms", id: "Ms" },
        { name: "Dr", id: "Dr" },
      ],
    };
  },
  computed: {
    ...mapState(["authUser"]),
  },
  created() {
    this.getUser();
  },
  methods: {
    ...mapActions(["fetchAuthUser", "logout"]),
    ...mapMutations(["setSuccessMessage", "setAuthUser"]),
    getUser() {
      return api.get("/auth-user").then((response) => {
        this.accountForm = {
          title: response.data.title,
          first_name: response.data.first_name,
          surname: response.data.surname,
          email: response.data.email,
          phone: response.data.phone,
          mobile: response.data.mobile,
        };
        this.loading = false;
      });
    },
    saveAccountInformation() {
      this.updatingAccount = true;
      this.errorBag.clear();
      api.patch("/auth-user", this.accountForm).then(
        (response) => {
          this.setSuccessMessage("Your account has been updated");
          this.updatingAccount = false;
          this.setAuthUser(response.data);
        },
        (errors) => {
          this.errorBag.store(errors.response.data);
          this.updatingAccount = false;
        }
      );
    },
    saveAvatar() {
      this.updatingAvatar = true;
      this.errorBag.clear();

      let formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("avatar", this.avatarFile);

      api
        .post("/auth-user/avatar", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(
          (response) => {
            this.setSuccessMessage("Your profile photo has been updated");
            this.updatingAvatar = false;
            this.setAuthUser(response.data);
          },
          (errors) => {
            this.errorBag.store(errors.response.data);
            this.updatingAvatar = false;
          }
        );
    },
  },
};
</script>
